var min_width, max_width, min_height, std_height, max_height, max_height_intervals, max_height_interval_price,
    max_height_with_cill_and_addon;
// var zoom_options = { 'innerZoom' : true, 'lensAutoCircle': true, 'lensClass': 'jetzoom-overlay' };
var stdColours = ['',202,170,156,153,215,214,200,217,216,181,9,111,192,143,155,91,49];
var $designer;

$(document).ready(function() {
    $('.big-hero-button[data-href]').click(function () {
        document.location.href = $(this).data('href');
    });
    $(function () {
        $('[data-toggle="popover"]').not('#colour-help, #trickle-vents-help').popover({
            animation: true,
            trigger: 'hover',
            placement: 'top',
        });
        $('#colour-help').popover({
            animation: true,
            trigger: 'hover',
            placement: 'left',
        });
        $('#trickle-vents-help').popover({
            animation: true,
            trigger: 'hover',
            placement: 'left',
        })
    });
    // set global vars
    $designer = $('#designer');
    min_width = $designer.data('min-width');
    max_width = $designer.data('max-width');
    min_height = $designer.data('min-height');
    std_height = $designer.data('std-height');
    max_height = $designer.data('max-height');
    max_height_intervals = $designer.data('max-height-intervals');
    max_height_interval_price = $designer.data('max-height-interval-price');
    max_height_with_cill_and_addon = max_height + 72;

    // focus width field
    $('input[name=width]').focus();

    // push the data-value to the given data-push-to field on any element with the .push class
    $('.push').click(function() {
        $('input#'+ $(this).data('push-to')).val($(this).data('value'));
        draw_door();
    });

    // apply active class to any element that has the .toggle class
    $('.toggle').click(function() {
        var $parent = $('#'+ $(this).data('parent-id'));
        $('.toggle', $parent).removeClass('active');
        $(this).addClass('active');
    });

    $('#play-config, .player').click(function() {
        handle_play_configuration();
    });

    $('button#close-video').click(function() {
        $('iframe#video').attr('src', '');
    });

    $('p#summary, div#summary-button').click(function() {
        // perform validation

        // if trickle vent is selected but no positions are selected, show warning...
        if($('input#ventilation_id').val() != '' && $('input#ventilation_id').val() != '0' && $('input#vent_positions').val() == '') {
            show_warning_message('You must choose the position for at least one trickle vent on your door configuration.<br />Click on the <strong>Ventilation</strong> and the colour of the vent you have selected to view the panel options.');
            return false;
        }

        // submit the form
        $('form#configuration').submit();
    });

    // activate jetzoom if required
    // $('.jetzoom').JetZoom(zoom_options);

    // if autoloading a previous design
    if($('input#autoload').length > 0) {
        process_size_entry();
        draw_door();
    }

    // action the close message button in message-box div
    $('a#close-message').click(function() {
        close_warning_message();
    });

    // load handlers
    handle_entry_point();
    handle_size_entry();
    handle_traffic_door();
    handle_options();
    handle_internal_colour();
    handle_external_colour();
    handle_selected_ral();
    handle_selected_ral_int();
    handle_selected_texture();
    handle_selected_foil();
    handle_blind_colour();
    handle_glazing_options();
    handle_technical_options();
    handle_hardware_options();
    handle_cylinder_options();
    handle_footer_buttons();
    handle_addon_positions();
    handle_vent_options();

    // get price when specific items are actioned
    $('div.door, div.colour').click(function() {
        get_price();
        if($(this).data('field') === 'glazing_id') {
            if ($(this).hasClass('integral_blinds')) {
                if ($(this).hasClass('active')) {
                    $('input#integral_blinds').val(1);
                    $('div.blind-holder').show();
                } else {
                    $('div.blind-holder').hide();
                    $('#blind_colour').val('');
                    $('div.blind-holder').html("<img src='/images/icons/colour-other.jpg' /><br />Choose your<br>Blind Colour");
                }
            } else {
                $('div.blind-holder').hide();
                $('#blind_colour').val('');
                $('input#integral_blinds').val('');
                $('div.blind-holder').html("<img src='/images/icons/colour-other.jpg' /><br />Choose your<br>Blind Colour");
            }
        }
        if($(this).data('field') == "self_cleaning"){
            $("[data-field='integral_blinds']").removeClass('active');
            $("div.blind-holder").removeClass('active');
            $('ul#blind-colours > li').removeClass('active');
            $('input#blind_colour').val('');
            $('div.blind-holder').html("<img src='/images/icons/colour-other.jpg' /><br />Choose your<br>Blind Colour");
            $('div.blind-holder').hide();
        }
    });

    // handle all options that update the redraw of the door preview image
    $('div#configs > div.door, div#external-colours div.colour, div#internal-colours div.colour, ul#colour-picker > li, ul#colour-picker-int > li, ul#colour-picker-tex > li, ul#colour-picker-foil > li, ul#colour-picker-blinds > li, div#glazing-options > div, div#blind-options > div, div#cill-options > div, div#vent-options > div, div#hardware-colours > div.colour, div#hardware > div').click(function() {
        draw_door();
    });

    // handle all options that update the redraw of the door preview image
    $('#external-colours div.colour, #internal-colours div.colour').click(function() {
        if(!$(this).hasClass('ral-holder') && !$(this).hasClass('ral-holder-int') && !$(this).hasClass('texture-holder')) {
            if($('input#door_type').val() == 1){
                if($(this).closest('.colours').is("#external-colours")){
                    close_ral_picker('ext');
                }
                if($(this).closest('.colours').is("#internal-colours")){
                    close_ral_picker('int');
                }
            } else {
                close_ral_picker('all');
            }
        }
    });

    if($('input#door_type').val() == 1) {
        $('#external-colours div.colour:not(.ral-holder)').click(function () {

            $('div#pvc_colour_match').html('<img src="' + $(this).find('img').attr('src') + '">' + $(this).data('colour-name') + "<br>" + $(this).data('ral-code'))
                .attr('data-value', $(this).data('value'));

            $('div#pvc_colour_match').data('match-internal', $(this).data('match-internal')).attr("data-match-internal",$(this).data('match-internal'));
            $('div#pvc_colour_match').data('value', $(this).data('value')).attr("data-value", $(this).data('value'));

            // push value to ral picker colour option external colour id field
            $('input#internal_colour_id').val($(this).data('value'));

            if($(this).data('ral-code') == 9003){
                $('#upvc-white').hide();
                $('div#pvc_colour_match').click();
            } else {
                $('#upvc-white').show();
            }
        });
    }

    $('#voucher_submit').on('click', function(e){

        let name      = $('#contact_name').val();
        let email     = $('#contact_email').val();
        let phone     = $('#contact_telephone').val();
        let company   = $('#contact_company').val();
        let address   = $('#contact_address').val();
        let town      = $('#contact_town').val();
        let postcode  = $('#contact_postcode').val();
        let door_type = $('#door_type').val();

        if(name != "" && email != "" && phone != "" && postcode != "") {
            if ($('#terms_checkbox').prop('checked')) {
                if ($('#voucher_code').val() != "") {
                    let data = $('#voucher_form').serializeArray();
                    data.push({name: "contact_name", value: name});
                    data.push({name: "contact_email", value: email});
                    data.push({name: "contact_telephone", value: phone});
                    data.push({name: "contact_company", value: company});
                    data.push({name: "contact_address", value: address});
                    data.push({name: "contact_town", value: town});
                    data.push({name: "contact_postcode", value: postcode});

                    $.post('/ajax/get_discount', $.param(data), function (result) {
                        if (result && typeof result !== 'undefined') {
                            var type = result.type;
                            var value = door_type == 1 ? parseFloat(result.upvc_value).toFixed(2) : parseFloat(result.aluminium_value).toFixed(2);
                            var rrp = parseFloat($('#door_price').val()).toFixed(2);
                            var discount = result.voucher_reduction;
                            var dpr = rrp - discount;
                            var rrp_inv_vat = rrp * 1.2;
                            var dpr_inv_vat = dpr * 1.2;
                            if ($('#client_type').val() == "trade") {
                                var vpr = type == 'percent' ? $('#price_net').val() * (value / 100) : value;
                                var yp = $('#price_net').val() - vpr;
                                var yv = yp * 0.2;
                                var yt = yp + yv;
                                $('#voucher_value').text('-£' + vpr.toFixed(2));
                                $('#your_price').text('£' + yp.toFixed(2));
                                $('#your_vat').text('£' + yv.toFixed(2));
                                $('#your_total').text('£' + yt.toFixed(2));
                                $('#voucher_code').css('border-color', '#3c763d');
                                $('#voucher_discount').show();
                            } else {
                                $('#homeowner_rrp').text('£' + parseFloat(rrp_inv_vat).toFixed(2)).show();
                                $('#homeowner_dpr').text('£' + parseFloat(dpr_inv_vat).toFixed(2)).show();
                                $('#customer-form-type').text('Order this door');
                                $('#customer-form-subtext').text('Fill out your door and submit this order');
                                $('#submit-details').html("Place order <i class='fa fa-chevron-right'></i>");
                                $('#voucher_code').css('border-color', '#3c763d');
                                $('#quote_or_order').val('order');
                                $('#homeowner_pricing').show();
                            }
                        } else {
                            $('#homeowner_pricing').hide();
                            $('#voucher_discount').hide();
                            $('#voucher_code').css('border-color', '#a94442');
                        }
                    });
                }
            } else {
                e.preventDefault();
                alert("You must check the Terms and Conditions checkbox to indicate you have read and accepted these before continuing.");
            }
        } else {
            e.preventDefault();
            alert("You must complete all required fields before continuing.");
        }
    });

    $('#trade_voucher_submit').on('click', function(){
        if($('#voucher_code').val() != ""){
            let data = $('#voucher_form').serialize();
            $.post('/ajax/get_discount', data, function (result) {
                if (result && typeof result !== 'undefined') {
                    var type = result.type;
                    var value = parseInt(result.value);
                    var rrp = parseInt($('#door_price').val());
                    var discount = result.voucher_reduction;
                    var dpr = rrp - discount;
                    var rrp_inv_vat = rrp * 1.2;
                    var dpr_inv_vat = dpr * 1.2;
                    if($('#client_type').val() == "trade"){
                        var vpr = type == 'percent' ? $('#price_net').val() * (value/100) : value;
                        var yp = $('#price_net').val() - vpr;
                        var yv = yp * 0.2;
                        var yt = yp + yv;
                        $('#voucher_value').text('-£'+vpr.toFixed(2));
                        $('#your_price').text('£'+yp.toFixed(2));
                        $('#your_vat').text('£'+yv.toFixed(2));
                        $('#your_total').text('£'+yt.toFixed(2));
                        $('#voucher_code').css('border-color', '#3c763d');
                        $('#voucher_discount').show();
                    } else {
                        $('#homeowner_rrp').text('£'+rrp_inv_vat.toFixed(2)).show();
                        $('#homeowner_dpr').text('£'+dpr_inv_vat.toFixed(2)).show();
                        $('#customer-form-type').text('Order this door');
                        $('#customer-form-subtext').text('Fill out your door and submit this order');
                        $('#submit-details').html("Place order <i class='fa fa-chevron-right'></i>");
                        $('#voucher_code').css('border-color', '#3c763d');
                        $('#homeowner_pricing').show();
                    }
                } else {
                    $('#homeowner_pricing').hide();
                    $('#voucher_discount').hide();
                    $('#voucher_code').css('border-color', '#a94442');
                }
                window.location.reload();
            });
        }
    });

    $('select.user_approval').on('change', function(){
        let data = $(this).closest('form').serialize();
        $.post('/ajax/user-approval/', data, function (result) {
            if (result) {

            } else {
                alert('There was an issue updating this user, please contact the administrator.');
            }
        });
    });

    $('#resend-email').on('click', function(e){
        e.preventDefault();

        let data = $('#resend_email_form').serialize();

        $('#resend-email').html('Sending... <i class="fa fa-spin fa-circle-o-notch"></i>').attr('disabled', 'disabled');

        $.post('/ajax/resend-email/', data, function (result) {
            if (result && typeof result !== 'undefined' && result == "Ok") {
                $('#resend-email').html('Mail sent!');
                $('#resend-email').prop("disabled", true);
            } else {
                alert('There was an issue sending this email, please try again. If the issue persists please notify the software vendor.');
            }
        });
    });

    $('#submit-details').on('click', function(e){
        if ($('#terms_checkbox').prop('checked')) {
            submitDoorQuote();
        } else {
            e.preventDefault();
            alert("You must check the Terms and Conditions checkbox to indicate you have read and accepted these before continuing.");
        }
    });

    $('#accept_quote').on('click', function(e){
        if ($('#terms_checkbox').prop('checked')) {
            $('form#accept_quote_form').submit();
        } else {
            e.preventDefault();
            alert("You must check the Terms and Conditions checkbox to indicate you have read and accepted these before continuing.");
        }
    });

    $('#pricingTabs a').click(function (e) {
        e.preventDefault()
        $(this).tab('show')
    });

    $('#select_installation_type').on('change', function(){

        let data = [];
        data.push({ name: "id", value: $('#door_id').val() });
        data.push({ name: "installation_inc", value: this.value });
        data.push({name: "_token", value: $('meta[name="csrf-token"]').attr('content')});

        $.post('/ajax/update-door', $.param(data), function (result) {
            if(result.success){
                Swal.fire('Success!', 'Door updated', 'success')
                    .then(() => {
                        location.reload();
                    });

            } else {
                Swal.fire({
                    title: "Error!",
                    text: "The door was not updated! Please refresh the page and try again.",
                    icon: "warning",
                    dangerMode: true,
                });
            }
        });
    })
});

function deleteDoor(id){

    Swal.fire({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this prospect or quote?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then(willDelete => {
        if (willDelete) {
            let data = [];
            data.push({name: "id", value: id});
            data.push({name: "_token", value: $('meta[name="csrf-token"]').attr('content')});

            $.post('/ajax/delete-door', $.param(data), function (result) {
                if(result.success){
                    Swal.fire('Success!', 'Door deleted', 'success')
                        .then(() => {
                            location.reload();
                        });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "The door was not deleted! Please refresh the page and try again.",
                        icon: "warning",
                        dangerMode: true,
                    });
                }
            });
        }
    });
}

function submitDoorQuote(){
    // handle registration form submit button
    $('button#submit-details').html('Submitting, please wait... <i class="fa fa-spin fa-circle-o-notch"></i>').attr('disabled', 'disabled');
    $('form#register').submit();
}


// show the warning message div
function show_warning_message(message) {
    $('p#message').html(message);
    $('div#configuration').slideUp('fast', function() {
        $('div#message-box').slideDown('fast');
    });
}


// close the warning message div
function close_warning_message() {
    $('div#message-box').slideUp('fast', function() {
        $('div#configuration').slideDown('fast');
    });
}


// handle actions from the landing page
function handle_entry_point() {
    // set selected door type option as active
    // $('div#entry-points > div').click(function() {
    //     $('div#entry-points > div').removeClass('active');
    //     $(this).addClass('active');
    // });

    $('div#entry-points > div:nth-child(2)').click(function() {
        Swal.fire({
            title: "UPVC doors unavailable",
            html: "We are currently updating our product range and are unable to supply a quote in uPVC.<br>Please choose our aluminium option or call us on 01179 724 551.",
            icon: 'info',
            confirmButtonText: "Dismiss",
        });
    });

    // handle the continue button when clicked
    $('button#continue').click(function() {
        $('i', $(this)).removeClass('fa-chevron-right').addClass('fa-circle-o-notch fa-spin');
        $(this).prop('disabled', true);
        var designer = $('div#entry-points > div.active').data('designer');
        window.location = '/'+ designer + '-designer';
    });
}


function handle_size_entry() {
    // handle the size entries
    $('input.size-control').change(function () {
        $designer.attr('data-size-is-valid', 'false');
        // process_size_entry();
    });
}


function process_size_entry() {
    console.log('process size entry');
    var width = $('input#width').val();
    var height = $('input#height').val();

    // check if we have enough to start building our price
    if(width != '' && !isNaN(width) && height != '' && !isNaN(height) && width >= min_width && width <= max_width && height >= min_height && height <= max_height_with_cill_and_addon ) {
console.log('a');
        // flag the size as being valid
        $designer.attr('data-size-is-valid', 'true');
        $('div#measurement-entry').removeClass('need-input');
        $('label#label_width').removeClass('error');
        $('label#label_width').next('div').removeClass('has-error');
        $('label#label_height').removeClass('error');
        $('label#label_height').next('div').removeClass('has-error');

        if($designer.data('size-is-valid') == false) {
            $('ul#build-options > li:not(#first)').addClass('closed');
            return;
        } else {
            $('ul#build-options > li:not(#first)').removeClass('closed');
        }

        var first_panel_available = 0;
        var simulate_click = true;

        $('div#configs div.door').each(function() {
            // if this door width matches width entered by user
            if(width >= $(this).data('min-width') && width <= $(this).data('max-width')) {
                $(this).show().addClass('selectable');
                if(first_panel_available == 0) {
                    first_panel_available = $(this).data('value');
                }
                if($(this).hasClass('active')) {
                    simulate_click = false;
                }
            } else {
                $(this).hide().removeClass('selectable');
            }
        });

        // check if we need to click the first item on the config list
        if(simulate_click) {
            $('div.door[data-value='+first_panel_available+']').click();
        }

        // get the price and update image
        get_price();

    } else {
        // add visual to incorrect measurements for

        // width
        if(width == '' ||  isNaN(width) || ( !isNaN(width) && ( width < min_width || width > max_width ) ) ) {
            $('div#measurement-entry').addClass('need-input');
            $('label#label_width').addClass('error');
            $('label#label_width').next('div').addClass('has-error');
        } else {
            $('div#measurement-entry').removeClass('need-input');
            $('label#label_width').removeClass('error');
            $('label#label_width').next('div').removeClass('has-error');
        }

        // height
        if(height != '' ||  isNaN(height) || ( !isNaN(height) && ( height < min_height || height > max_height_with_cill_and_addon ) ) ) {
            $('div#measurement-entry').addClass('need-input');
            $('label#label_height').addClass('error');
            $('label#label_height').next('div').addClass('has-error');
        } else {
            $('div#measurement-entry').removeClass('need-input');
            $('label#label_height').removeClass('error');
            $('label#label_height').next('div').removeClass('has-error');
        }
    }
}


function handle_traffic_door() {
    $('ul#slide-direction > li').click(function() {
        $('ul#slide-direction > li').each(function() {
            $(this).find('img').attr('src', $(this).data('off'));
        });

        $(this).find('img').attr('src', $(this).data('on'));
        draw_door();
    });
}


function handle_options() {
    // action build options picker
    $('ul#build-options > li').click(function() {
        // highlighted selected option
        var step = $(this);

        // check if we can change the step
        if(step.data('step') == undefined) return;
        if($designer.attr('data-size-is-valid') == 'false') {
            highlight_measurement_entry_container();
            return;
        } else {
            remove_highlight_measurement_entry_container();
            // $('div#measurement-entry').removeClass('need-input');
        }

        // if on hardware colour option, make sure one is selected
        if($(this).data('step') == 5) {

            let hwcol_active = false;

            $('#hardware-colours div.colour').each(function() {
                if ($(this).hasClass('active')) {
                    hwcol_active = true;
                    // in case we are editing a door, select the colour
                    // so that options in other colours are hidden
                    select_hardware_colour(false);
                }
            });

            if(!hwcol_active){
                Swal.fire("Please select a hardware colour");
                return;
            }
        }

        // if on hardware colour option, make sure one is selected
        if($(this).data('step') == 6) {

            let hw_active = false;

            $('#hardware div.colour').each(function() {
                if ($(this).hasClass('active')) {
                    hw_active = true;
                    // in case we are editing a door, select the colour
                    // so that options in other colours are hidden
                    update_hardware_selection();
                }
            });

            if(!hw_active){
                Swal.fire("Please select a handle");
                return;
            }
        }

        // if on blind colour option, make sure one is selected
        if($(this).data('step') == 7) {

            if(($('#glazing_id').val() == "17" || $('#glazing_id').val() == "18")
                && $("#blind_colour").val() == ""){
                Swal.fire("Please select a colour for your blinds");
                return;
            }
        }

        // if on addons step, make sure one is selected if there are trickle vents
        // if($(this).data('step') == 8) {
        //     if($('#ventilation_id').val() != "" && $("#addon_id_top").val() == ""){
        //         Swal.fire("Doors with trickle vents require a top addon, please select one");
        //         return;
        //     }
        // }

        $('ul#build-options > li').removeClass('active');
        $(this).addClass('active');

        // show associated configuration block
        $('div#configuration > div').hide();
        $('div#configuration > div[data-step-group='+ step.data('step') +']').show();

        // if the block has scrollers, refire them
        if(step.data('has-scroll') !== undefined) {
            intialise_slider(step.data('step'));
        }

        // if not on colours option, ensure that the door preview is shown
        if($(this).data('step') != 3) {
            close_ral_picker('');

            // if external colour is blank, use default
            if($('input#external_colour_id').val() == '') {
                $('input#external_colour_id').val($('input#external_colour_id').attr('data-default'));
            }
        } else {
            // simulate click on active colour to ensure correct values for ext and int frame colours are used
            if(!$('div#external-colours div.active').hasClass('ral-holder')) {
                // $('div#external-colours div.active').click();
            } else {
                // ensure the matching internal colour matches that of the external ral colour
                // $('div#internal-colour-match').html($('div#external-colours div.ral-holder').html());
            }
        }

        // if on ventilation option, update the selected vent options
        if($(this).data('step') == 6) {
            update_vent_positions_field();
        }

        // if not on ventilation option, close down the vent picker
        if($(this).data('step') != 6) {
            close_vent_preview();
        }

        // show the final footer button based on current step

        // back button
        if($(this).data('step') == 1) {
            $('.footer-buttons #back-button').addClass('wait');
        } else {
            $('.footer-buttons #back-button').removeClass('wait');
        }

        // next and summary buttons
        if($(this).data('step') == 9) {
            $('.footer-buttons #next-button').addClass('hidden');
            $('.footer-buttons #summary-button').removeClass('hidden');
        } else {
            $('.footer-buttons #next-button').removeClass('hidden');
            $('.footer-buttons #summary-button').addClass('hidden');
        }

        // close warning message if selected
        if($('div#message-box').is(':visible')) {
            close_warning_message();
        }
    });
}


function close_vent_preview() {
    $('div#vent-preview').slideUp('slow', function() {
        $('div#door-preview').slideDown('slow');
    });
}


function handle_external_colour() {
    // action the choose ral option from the external colours
    $('div#external-colours div.colour').click(function() {
        // if ral picker selected
        if($(this).hasClass('ral-holder')) {
            // use selected ral value as the external colour id
            $('input#external_colour_id').val($('ul#ral-colours > li.active').data('ral-id'));
            if($('input#door_type').val() == 1) {
                $('#lead-times').text('6 weeks');
            } else {
                $('#lead-times').text('4 weeks');
            }
        } else if($(this).hasClass('texture-holder')) {
            // use selected ral value as the external colour id
            $('input#external_colour_id').val($('ul#texture-colours > li.active').data('ral-id'));
            $('#lead-times').text('4 weeks');
        } else {
            // just use normal default external colour id
            $('input#external_colour_id').val($(this).data('value'));
            if($('input#door_type').val() == 1) {

                if($.inArray(parseInt($('input#internal_colour_id').val()), stdColours) !== -1){
                    $('#lead-times').text('7-14 days');
                } else {
                    $('#lead-times').text('6 weeks');
                }
            } else {
                $('#lead-times').text('3 weeks');
            }

            // push selected standard colour to internal colour match
            $('div#internal-colour-match').html($(this).html());
        }

        // if the choose ral option is picked and the door preview is shown, hide the preview and show the ral colours
        if($(this).data('pick-ral') == '1') {
            var $close = $('div#colour-picker-tex, div#colour-picker-foil, div#door-preview');
            var $open = $('div#colour-picker');

            $('div#visual-container > div').removeClass('active');
            $open.addClass('active');

            // slide up and hide the door preview
            $close.slideUp('slow', function() {
                $(this).hide();

                // remove slim scroll
                $close.slimscroll({destroy: true});
                $('div.slimScrollBar, div.slimScrollRail').remove();

                $open.slimscroll({
                    height: '340',
                    railVisible: true,
                    alwaysVisible: true
                }).slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected').val('1');

            // show the internal match colour
            $('div#internal-colour-match').html($(this).html()).show();

            // push the select ral colour to external colour id field
            $('input#external_colour_id').val($('ul#ral-colours > li.active').data('ral-id'));
        }

        // if the choose ral option is picked and the door preview is shown, hide the preview and show the ral colours
        if($(this).data('pick-tex') == '1' ) {
            var $close = $('div#colour-picker, div#colour-picker-foil, div#door-preview');
            var $open = $('div#colour-picker-tex');

            $('div#visual-container > div').removeClass('active');
            $open.addClass('active');

            // slide up and hide the door preview
            $close.slideUp('slow', function() {
                $(this).hide();

                // remove slim scroll
                $close.slimscroll({destroy: true});
                $('div.slimScrollBar, div.slimScrollRail').remove();

                $open.slimscroll({
                    height: '340',
                    railVisible: true,
                    alwaysVisible: true
                }).slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected').val('1');

            // show the internal match colour
            $('div#internal-colour-match').html($(this).html()).show();

            // push the select ral colour to external colour id field
            $('input#external_colour_id').val($('ul#texture-colours > li.active').data('ral-id'));
        }

        // if the choose foil option is picked and the door preview is shown, hide the preview and show the foil colours
        if($(this).data('pick-foil') == '1' ) {
            var $close = $('div#colour-picker, div#colour-picker-tex, div#door-preview');
            var $open = $('div#colour-picker-foil');

            $('div#visual-container > div').removeClass('active');
            $open.addClass('active');

            // slide up and hide the door preview
            $close.slideUp('slow', function() {
                $(this).hide();

                // remove slim scroll
                $close.slimscroll({destroy: true});
                $('div.slimScrollBar, div.slimScrollRail').remove();

                $open.slimscroll({
                    height: '340',
                    railVisible: true,
                    alwaysVisible: true
                }).slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected').val('1');

            // show the internal match colour
            $('div#internal-colour-match').html($(this).html()).show();

            // push the select ral colour to external colour id field
            $('input#external_colour_id').val($('ul#texture-colours > li.active').data('ral-id'));
        }

        // if the colour picked is not a ral picker and the colour picker is shown, hide it and draw the door preview
        if($(this).data('pick-ral') == '0' && ($('div#colour-picker').hasClass('active') || $('div#colour-picker-int').hasClass('active') || $('div#colour-picker-tex').hasClass('active'))) {
            var $close = $('div#colour-picker, div#colour-picker-int, div#colour-picker-tex, div#colour-picker-foil');
            var $open = $('div#door-preview');

            $close.slideUp('slow', function () {
                $('div#visual-container > div').removeClass('active');
                $open.addClass('active');

                // remove slim scroll
                $close.slimscroll({destroy: true});
                $('div.slimScrollBar, div.slimScrollRail').remove();

                // show the door preview
                $open.slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected').val('0');
        }

        // update price
        get_price();
    });
}

function handle_internal_colour() {
    // action the choose ral option from the external colours
    $('div#internal-colours div.colour').click(function() {
        // if ral picker selected
        if($(this).hasClass('ral-holder-int')) {
            // use selected ral value as the internal colour id
            $('input#internal_colour_id').val($('ul#ral-colours-int > li.active').data('ral-id'));
            $('#lead-times').text('6 weeks');
        } else {
            // just use normal default internal colour id
            $('input#internal_colour_id').val($(this).data('value'));
            if($.inArray(parseInt($('input#external_colour_id').val()), stdColours) !== -1){
                $('#lead-times').text('7-14 days');
            } else {
                $('#lead-times').text('6 weeks');
            }

            // push selected standard colour to internal colour match
            $('div#internal-colour-match').html($(this).html());
        }

        // if the choose ral option is picked and the door preview is shown, hide the preview and show the ral colours
        if($(this).data('pick-ral') == '1' && $('div#door-preview').hasClass('active')) {
            var $close = $('div#door-preview, div#colour-picker');
            var $open = $('div#colour-picker-int');

            $('div#visual-container > div').removeClass('active');
            $open.addClass('active');

            // slide up and hide the door preview
            $close.slideUp('slow', function() {
                $(this).hide();
                $open.slimscroll({
                    height: '340',
                    railVisible: true,
                    alwaysVisible: true
                }).slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected_int').val('1');

            // show the internal match colour
            $('div#internal-colour-match').html($(this).html()).show();

            // push the select ral colour to external colour id field
            $('input#internal_colour_id').val($('ul#ral-colours-int > li.active').data('ral-id'));
        }

        // if the colour picked is not a ral picker and the colour picker is shown, hide it and draw the door preview
        if($(this).data('pick-ral') == '0' && $('div#colour-picker').hasClass('active')) {
            var $close = $('div#colour-picker, div#colour-picker-int');
            var $open = $('div#door-preview');
            $close.slideUp('slow', function () {
                $('div#visual-container > div').removeClass('active');
                $open.addClass('active');

                // remove slim scroll
                $close.slimscroll({destroy: true});
                $('div.slimScrollBar, div.slimScrollRail').remove();

                // show the door preview
                $open.slideDown('slow');
            });

            // set holding ral field
            $('input#ral_selected').val('0');
        }

        // update price
        get_price();
    });
}


function handle_selected_ral() {
    // action selected ral colour
    $('ul#ral-colours > li').click(function() {
        $('ul#ral-colours > li').removeClass('active');
        $(this).addClass('active');

        // push selected ral to external and internal colour options
        var colour_id = $(this).data('ral-id');
        var colour_name = $(this).data('ral-name');
        var colour_hex = $(this).data('ral-hex');
        var colour_code = $(this).data('ral-code');
        var colour_price = $(this).data('ppp');
        var $holder = $('div.ral-holder');

        $holder.data('ppp', colour_price);
        $holder.attr('data-ppp', colour_price);

        $holder.html('<div class="ral-colour-holder"><div class="colour-box" style="background-color:'+ colour_hex +'"></div></div>'+ colour_name +'<br />'+ colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#external-colours div.ral-holder').attr('data-value', colour_id);
        $('input#external_colour_id').val(colour_id);

        // highlight the external colour option
        $('div#external-colours div.ral-holder').addClass('active');

        // draw door and close the ral picker
        draw_door();
        close_ral_picker('ral');

        // update price
        get_price();
    });
}


function handle_selected_ral_int() {
    // action selected ral colour
    $('ul#ral-colours-int > li').click(function() {
        $('ul#ral-colours-int > li').removeClass('active');
        $(this).addClass('active');

        // push selected ral to external and internal colour options
        var colour_id = $(this).data('ral-id');
        var colour_name = $(this).data('ral-name');
        var colour_hex = $(this).data('ral-hex');
        var colour_code = $(this).data('ral-code');
        var colour_price = $(this).data('ppp');
        var $holder = $('div.ral-holder-int');

        $holder.data('ppp', colour_price);
        $holder.attr('data-ppp', colour_price);

        $holder.html('<div class="ral-colour-holder-int"><div class="colour-box" style="background-color:'+ colour_hex +'"></div></div>'+ colour_name +'<br />'+ colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#internal-colours div.ral-holder-int').attr('data-value', colour_id);
        $('input#internal_colour_id').val(colour_id);

        // highlight the external colour option
        $('div#internal-colours div.ral-holder-int').addClass('active');

        // if the internal colour match is selected, place the matching id in the holding field
        // if($('div#internal-colour-match').hasClass('active')) {
        //     $('input#internal_colour_id').val(colour_id);
        // }

        // draw door and close the ral picker
        draw_door();
        close_ral_picker('ral');

        // update price
        get_price();
    });
}

function handle_selected_texture() {
    // action selected ral colour
    $('ul#texture-colours > li').click(function() {
        $('ul#texture-colours > li').removeClass('active');
        $(this).addClass('active');

        // push selected ral to external and internal colour options
        var colour_id = $(this).data('ral-id');
        var colour_name = $(this).data('ral-name');
        var colour_hex = $(this).data('ral-hex');
        var colour_code = $(this).data('ral-code');
        var colour_price = $(this).data('ppp');
        var $holder = $('div.texture-holder');

        $holder.data('ppp', colour_price);
        $holder.attr('data-ppp', colour_price);

        $holder.html('<div class="texture-colour-holder"><div class="colour-box" style="background-color:'+ colour_hex +'"></div></div>'+ colour_name +'<br />'+ colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#external-colours div.texture-holder').attr('data-value', colour_id);
        $('input#external_colour_id').val(colour_id);

        // highlight the external colour option
        $('div#external-colours div.texture-holder').addClass('active');

        // if the internal colour match is selected, place the matching id in the holding field
        // if($('div#internal-colour-match').hasClass('active')) {
        //     $('input#internal_colour_id').val(colour_id);
        // }

        // draw door and close the ral picker
        draw_door();
        close_ral_picker('texture');

        // update price
        get_price();
    });
}

function handle_selected_foil() {
    // action selected ral colour
    $('ul#foil-colours > li').click(function() {
        $('ul#foil-colours > li').removeClass('active');
        $(this).addClass('active');

        // push selected ral to external and internal colour options
        var colour_id = $(this).data('ral-id');
        var colour_name = $(this).data('ral-name');
        var colour_hex = $(this).data('ral-hex');
        var colour_code = $(this).data('ral-code');
        var colour_price = $(this).data('ppp');
        var $holder = $('div.foil-holder');

        $holder.data('ppp', colour_price);
        $holder.attr('data-ppp', colour_price);

        $holder.html('<div class="foil-colour-holder"><div class="colour-box" style="background-color:'+ colour_hex +'"></div></div>'+ colour_name +'<br />'+ colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#external-colours div.foil-holder').attr('data-value', colour_id);
        $('input#external_colour_id').val(colour_id);

        // highlight the external colour option
        $('div#external-colours div.foil-holder').addClass('active');

        // repeat above for internal on UPVC doors
        $('div#pvc_colour_match').html('<div class="ral-colour-holder-int"><div class="colour-box" style="background-color:' + colour_hex + '"></div></div>' + colour_name + '<br />' + colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#pvc_colour_match').data('value', colour_id).attr('data-value', colour_id);
        $('div#pvc_colour_match').data('colour_match', colour_id).attr('data-colour_match', colour_id);
        $('input#internal_colour_id').val(colour_id);

        if(colour_code == 9003){
            $('#upvc-white').hide();
            $('div#pvc_colour_match').click();
        } else {
            $('#upvc-white').show();
        }

        // if the internal colour match is selected, place the matching id in the holding field
        // if($('div#internal-colour-match').hasClass('active')) {
        //     $('input#internal_colour_id').val(colour_id);
        // }

        // draw door and close the ral picker
        draw_door();
        close_ral_picker('foil');

        // update price
        get_price();
    });
}

function close_ral_picker(selected) {
    // close the colour picker and show the door preview
    var $close = $('div#colour-picker, div#colour-picker-int, div#colour-picker-tex, div#colour-picker-foil');
    //var $close2 = $('div#colour-picker-int');
    var $open = $('div#door-preview');
    var door_type = $('#door_type').val();
    var label = door_type == 1 ? 'Foil Range' : 'Choose RAL';
    $close.slideUp('slow', function () {
        $('div#visual-container > div').removeClass('active');
        $open.addClass('active');

        // remove slim scroll
        $close.slimscroll({destroy: true});
        $('div.slimScrollBar, div.slimScrollRail').remove();

        // show the door preview
        $open.slideDown('slow');

        //reset the ral/texture colour picker icons if another colour is selected...
        if(selected == 'ral'){
            $('div.texture-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />Choose a<br>Texture Colour");
        } else if(selected == 'texture') {
            $('div.ral-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
        } else if(selected == 'foil') {
            $('div.ral-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
        } else if(selected == 'ext') {
            $('div.ral-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
        } else if(selected == 'int') {
            $('div.ral-holder-int').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
        } else if(selected == 'all') {
            if($.inArray(parseInt($('input#external_colour_id').val()), stdColours) !== -1) {
                $('div.ral-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
            }
            if($.inArray(parseInt($('input#internal_colour_id').val()), stdColours) !== -1) {
                $('div.ral-holder-int').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />" + label);
            }
            $('div.texture-holder').html("<img src='" + window.location.protocol + "//" + window.location.host + "/images/icons/colour-other.jpg' /><br />Choose a<br>Texture Colour");
        }

    });
}

function handle_blind_colour() {
    // action the choose ral option from the external colours
    $('div#glazing-options div.colour, div#blind-options div.colour').click(function() {
        // if ral picker selected
        if ($(this).hasClass('blind-holder')) {
            // use selected ral value as the external colour id
            $('input#blind-colours').val($('ul#blind-colours > li.active').data('ral-id'));
        }

        // if the choose ral option is picked and the door preview is shown, hide the preview and show the ral colours
        if ($(this).data('pick-blind') == '1' && $('div#door-preview').hasClass('active')) {
            var $close = $('div#door-preview');
            var $open = $('div#colour-picker-blinds');

            $('div#visual-container > div').removeClass('active');
            $open.addClass('active');

            // slide up and hide the door preview
            $close.slideUp('slow', function () {
                $(this).hide();
                $open.slimscroll({
                    height: '340',
                    railVisible: true,
                    alwaysVisible: true
                }).slideDown('slow');
            });

            // push the select ral colour to external colour id field
            $('input#blind-colours').val($('ul#blind-colours > li.active').data('ral-id'));
        }
    });
    // action selected ral colour
    $('ul#blind-colours > li').click(function() {
        $('ul#blind-colours > li').removeClass('active');
        $(this).addClass('active');

        // push selected ral to external and internal colour options
        var colour_id = $(this).data('ral-id');
        var colour_name = $(this).data('ral-name');
        var colour_hex = $(this).data('ral-hex');
        var colour_code = $(this).data('ral-code');

        var $holder = $('div.blind-holder');
        $holder.html('<div class="blind-colour-holder"><div class="colour-box" style="background-color:'+ colour_hex +'"></div></div>'+ colour_name +'<br />'+ colour_code)
            .attr('data-value', colour_id);

        // push value to ral picker colour option external colour id field
        $('div#blind-colours div.blind-holder').attr('data-value', colour_id);
        $('input#blind_colour').val(colour_id);

        // highlight the external colour option
        $('div.blind-holder').addClass('active');

        // if the internal colour match is selected, place the matching id in the holding field
        // if($('div#internal-colour-match').hasClass('active')) {
        //     $('input#internal_colour_id').val(colour_id);
        // }

        // draw door and close the ral picker
        draw_door();
        close_blind_picker();
    });
}

function close_blind_picker() {
    // close the colour picker and show the door preview
    var $close = $('div#colour-picker-blinds');
    //var $close2 = $('div#colour-picker-int');
    var $open = $('div#door-preview');
    $close.slideUp('slow', function () {
        $('div#visual-container > div').removeClass('active');
        $open.addClass('active');

        // remove slim scroll
        $close.slimscroll({destroy: true});
        $('div.slimScrollBar, div.slimScrollRail').remove();

        // show the door preview
        $open.slideDown('slow');

    });
}

function handle_cylinder_options(){
    // // get the group of divs for each option
    // var $group = $('div#hardware-cylinder div.colour');
    //
    // // handle the option click
    // $group.click(function() {
    //     let $field = $('#'+ $(this).data('field'));
    //
    //     // perform action
    //     if ($(this).hasClass('active')) {
    //         $(this).removeClass('active');
    //         $field.val('');
    //     } else {
    //         $(this).addClass('active');
    //         $field.val($(this).data('value'));
    //     }
    // });
}


function handle_glazing_options() {
    // get the group of divs for each option
    var $group = $('div#glazing-options > div, div#blind-options div');
    var $primary = $('div#glazing-options > div#primary-glazing-option');

    // handle the option click
    $group.click(function() {
        var $selected = $(this);
        var $field = $('#'+ $selected.data('field'));

        // check if this option clears all other options
        if($selected.data('clear-all') != undefined) {
            $group.removeClass('active');
            $selected.addClass('active');
            $field.val($selected.data('value'));
            $('input.glazing-dependent').val('');
            return;
        }

        // check if this option is part of a group, if so - clear other grouped options and just highlight selected one
        if($selected.data('group') != undefined) {
            var group_id = $selected.data('group');
            $group.each(function() {
                if($(this).data('group') != undefined && $(this).data('group') == group_id) {
                    $(this).removeClass('active');
                }
            });
            $selected.addClass('active');
            $field.val($selected.data('value'));
            return;
        }

        // if this option can be toggled, perform action
        if(!$primary.hasClass('active') && $selected.attr('data-toggle') == 'true') {
            if($selected.hasClass('active')) {
                $selected.removeClass('active');
                $field.val('');
            } else {
                $selected.addClass('active');
                $field.val($selected.data('value'));
            }
        }
        draw_door();
    });
}


function handle_hardware_options() {

    $('#hardware-colours div.colour').click(function() {

        select_hardware_colour(true);

    });
}

function select_hardware_colour(force_update){

    let first = true;
    let hwcol = $('#hardware_colour').val();

    $('#hardware div.colour').each(function(){

        if($(this).data('colour') == hwcol){

            $(this).show();

            if(force_update) {
                if(first){
                    $(this).addClass('active');
                    $('input#hardware_handle_id').val($(this).data('value'));
                    first = false;
                }
            }

        } else {

            $(this).removeClass('active').hide();

        }
    })
}

function update_hardware_selection() {

    $('#hardware div.colour').each(function(){

        if($(this).hasClass('active')){

            $('#' + $(this).data('field')).val($(this).data('value'));

        }
    })
}


function handle_technical_options() {
    // handle the option click
    $('div.technical-options > div').click(function() {
        var $selected = $(this);
        var $group = $('div', $selected.parent('div'));
        var $field = $('#'+ $selected.data('field'));

        // check if selected option is toggle, if so, check to see if already selected and remove
        if($selected.attr('data-toggle') == 'true' && $selected.hasClass('active')) {
            $selected.removeClass('active');
            $field.val('');
            return;
        }

        // dirty hack
        if($selected.data('field') == "ventilation_id"){
            if($(this).is('#no-vents')){
                $group.removeClass('active');
                $selected.addClass('active');
                $field.val($selected.data('value'));
            } else if($(this).is('#add-vents')){
                $('#no-vents').removeClass('active');
                $selected.addClass('active');
                $('.vent-colours:first').addClass('active');
                $field.val($('.vent-colours:first').data('value'));
            } else {
                $.each($group, function() {
                    if(!$(this).is('#no-vents') && !$(this).is('#add-vents')) {
                        $(this).removeClass('active');
                    }
                    $selected.addClass('active');
                    $field.val($selected.data('value'));
                });
            }
        } else {
            // set selected element as active and registered associated field name with selected value
            $group.removeClass('active');
            $selected.addClass('active');
            $field.val($selected.data('value'));
        }

        if($selected.data('field') == "cill_id") {

            // do some addon stuff

            let height = $('input#height').val();
            let height_extra = height - max_height;
            let cill_id = $('input#cill_id').val();
            let group = $("[data-field='addon_id_top']");

            if(height_extra <= 30 && height_extra > 0){

                if(cill_id == "" || cill_id == 0 || cill_id == null || cill_id == undefined) {

                    $(group[0]).addClass('disabled');

                    $('input#addon_required').val(1);

                } else {

                    group.removeClass('disabled');

                    $('input#addon_required').val(0);

                }

            }

        }

    });

    set_drainage_option();
}

function set_drainage_option()
{
    var cill = $('input#cill_id').val();
    var group = $("[data-field='drainage_id']");

    if (cill != '' && cill != '0' && cill != 0 && cill != null) {
        $(group).removeClass('active');
        $(group[1]).addClass('active');
        $('#drainage_id').val($(group[1]).data('value'));
    } else {
        $(group).removeClass('active');
        $(group[2]).addClass('active');
        $('#drainage_id').val($(group[2]).data('value'));
    }
}


function handle_footer_buttons() {
    $('div.footer-buttons > div').click(function () {
        var $button = $(this);
        if($button.attr('id') == 'back' || $button.attr('id') == 'next') {
            var current = $('ul#build-options > li.active').data('step');
            console.log('currentPage: ' + current)
            if(current == 1) {
                process_size_entry();
                checkSizes();
            } else {
                if($button.attr('id') == 'back') { current --; }
                if($button.attr('id') == 'next') { current ++; }
                $('ul#build-options > li[data-step=' + current + ']').click();
            }
        }
    });
}

function checkSizes() {
    var width = $('input#width').val();
    var height = $('input#height').val();

    // check if we have enough to start building our price
    if(width != '' && !isNaN(width) && height != '' && !isNaN(height)) {

        // check if the width is within acceptable range
        if (width >= min_width && width <= max_width && height >= min_height && height <= max_height_with_cill_and_addon) {

            if (height > max_height) {
                // set some vars
                let height_extra = height - max_height;
                let html, action, showCancelBtn, confirmButtonTxt;

                // reset some things
                $('#cill-options div:nth-child(1)').show();
                $('input#addon_required').val(0);

                let group = $("[data-field='addon_id_top']");
                let no_addon_selected = $('input#addon_id_top').val() == $(group[0]).data('value');
                let no_cill_selected = $('input#cill_id').val() == $('#cill-options div:nth-child(1)').data('value');

                // cill
                // OR addon required
                console.log('height', height);
                console.log('max_height', max_height);
                console.log('height_extra', height_extra);
                if (height_extra <= 30) {
                    html = 'Either a cill or an add-on is required for a door this high, by default a cill has been added to your door.';
                    action = 'either';
                    showCancelBtn = false;
                    confirmButtonTxt = 'Okay';

                    if (!no_cill_selected) {
                        $('#cill-options div:nth-child(1)').hide();
                    }
                }
                // addon
                else if (height_extra > 30 && height_extra <= 42) {
                    html = 'An addon is required for a door this high, and has been added to your door.';
                    action = 'addon';
                    showCancelBtn = false;
                    confirmButtonTxt = 'Addon';
                    confirmButtonTxt = 'Okay';
                }
                // addon AND cill
                else {
                    html = 'An addon and a cill are required for a door this high, and have been added to your door.';
                    action = 'both';
                    showCancelBtn = false;
                    confirmButtonTxt = 'Okay';

                    if (!no_cill_selected) {
                        $('#cill-options div:nth-child(1)').hide();
                    }
                }

                console.log(action, no_addon_selected, no_cill_selected)
                //
                // if(
                //     (action == "either" && no_addon_selected && no_cill_selected)
                //     || (action == "addon" && no_addon_selected)
                //     || (action == "both" && (no_addon_selected || no_cill_selected))
                // ) {

                Swal.fire({
                    title: 'Your Door Is Over 2400mm High',
                    html: html,
                    showCancelButton: showCancelBtn,
                    confirmButtonText: confirmButtonTxt,
                    cancelButtonText: 'Cill',
                    cancelButtonColor: '#2778c4',
                    icon: 'question',
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('isConfirmed');

                        // always going to need an addon in this scenario...
                        if(action != 'either') {
                            // set a hidden param
                            $('input#addon_required').val(1);

                            group.removeClass('btn-primary btn-default active').addClass('btn-default');
                            if ($('input#door_type').val() == 1) {
                                $(group[0]).addClass('disabled');
                                $(group[1]).addClass('disabled');
                                $(group[2]).addClass('disabled');
                                $(group[3]).removeClass('btn-default').addClass('btn-primary active');
                                $('input#addon_id_top').val($(group[3]).data('value'));
                            } else {
                                $(group[0]).addClass('disabled');
                                $(group[1]).removeClass('btn-default').addClass('btn-primary active');
                                $('input#addon_id_top').val($(group[1]).data('value'));
                            }
                        }

                        if (action === "both") {

                            // add a cill and disable the none option
                            let cill_id = $('#cill-options div:nth-child(3)').data('value');
                            $('input#cill_id').val(cill_id);
                            $('#cill-options div:nth-child(1)').hide();

                        }

                        $('ul#build-options > li[data-step=2]').click();
                    }
                    if (result.isDismissed) {
                        console.log('isDismissed');

                        // add ONLY a cill
                        let cill_id = $('#cill-options div:nth-child(3)').data('value');
                        $('input#cill_id').val(cill_id);
                        $('#cill-options div:nth-child(1)').hide();

                        // re-enable the top addon none option
                        $(group[0]).removeClass('disabled');

                        $('ul#build-options > li[data-step=2]').click();
                    }
                });
                // }
            } else {
                // show the none options for cills and addons
                $('#cill-options div:nth-child(1)').show();
                $('input#addon_required').val(0);
                let group = $("[data-field='addon_id_top']");
                $(group[0]).removeClass('disabled');
                $('ul#build-options > li[data-step=2]').click();
            }
        } else {

        }
    }
}


function handle_addon_positions() {

    $('#options-technical .btn-group label').on('click', function() {
        var $selected = $(this);
        var $group = $('label', $selected.parent('div'));
        var $field = $('#' + $selected.data('field'));
        $('input#addon_required').val(0);

        if(!$selected.hasClass('disabled')){
            $group.removeClass('btn-default btn-primary').addClass('btn-default');
            $selected.removeClass('btn-default').addClass('btn-primary');
            $field.val($selected.data('value'));
        }

        let height = $('input#height').val();
        let height_extra = height - max_height;
        let top_addon_id = $('input#addon_id_top').val();

        if(height_extra <= 30 && height_extra > 0){
            if(top_addon_id == "" || top_addon_id == 0 || top_addon_id == null || top_addon_id == undefined) {
                $('#cill-options div:nth-child(1)').hide();
            } else{
                $('#cill-options div:nth-child(1)').show();
                $('input#addon_required').val(1);
            }
        }

        get_price();
    });
}


function handle_vent_options() {
    // hide and unhide the colour options if no vents is selected/unselected
    $('#no-vents').on('click', function() {
        $('.vent-colours').hide();
    });
    $('#add-vents').on('click', function() {
        $('.vent-colours').show();
    });

    // action the vent option when clicked to open the vent picker
    $('div#vent-options').on('click', 'div', function() {
        if($(this).data('value') == '0') {
            close_vent_preview();
        } else {
            $('div#door-preview').slideUp('slow', function () {
                $('div#vent-preview').slideDown('slow');
            });
        }
    });

    // action the vent picker from the revealed vent options
    $('div#vent-picker').on('click', 'div', function() {
        if($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }

        // set selected vents and update the door
        update_vent_positions_field();
        draw_door();
    });
}


function update_vent_positions_field() {
    // get the selected vents and set as a csv
    var vents = '';
    var panels = $('input#panels').val();
    $('div#vent-picker > div.active').each(function() {
        if($(this).data('vent') <= panels) {
            vents += $(this).data('vent') + ',';
        }
    });
    // remove trailing comma and set to vents field
    vents = ( vents.substr(-1) == ',' ? vents.substr(0, vents.length-1) : vents );
    $('input#vent_positions').val(vents);
}


function handle_play_configuration() {
    // get the selected config
    if($('div#configs > div.active').length > 0) {
        var $config = $('div#configs > div.active');
        var source = 'data-source-' + ( $('input#traffic_door').val() );
        var youtube_link = 'https://www.youtube.com/embed/'+ $config.attr(source);
        $('iframe#video').attr('src', youtube_link);
        $('#video-player').modal({
            keyboard: false
        });
    } else {
        highlight_measurement_entry_container();
        return;
    }
}


function highlight_measurement_entry_container() {
    $('div#measurement-entry').addClass('need-input');
    if($('input#width').val() == '') {
        $('input#width').focus();
    } else {
        $('input#height').focus();
    }
    Swal.fire("Please check your measurements");
}


function remove_highlight_measurement_entry_container() {
    $('div#measurement-entry').removeClass('need-input');
    $('div#summary > p#summary').slideDown('slow');
}


function draw_door() {
    var door_type = $('input#door_type').val();
    var panels = $('input#panels').val();
    var traffic = $('input#traffic_door').val();
    var hardware = $('input#hardware_handle_id').val();
    var colour = '9003';
    var blinds = '0';
    var vent = '0';
    var vents = $('input#vent_positions').val();

    // get the colour to use for drawing the door
    if($('input#external_colour_id').val() != '') {
        colour = $('input#external_colour_id').val();
    }

    // if blinds are selected, add required flag to params
    if($('input#integral_blinds').val() != '') {
        blinds = 1;
    }

    // if vents are selected, add required flag to params
    var glass = $('input#glazing_id').val();

    // if vents are selected, add required flag to params
    var vent = $('input#ventilation_id').val();

    if((vent != '' && vent != '0' && vent != 0 && vent != null) || $('input#addon_required').val() == 1) {
        //console.log('there are vents!');
        // do something to disable to the 'none' option for top addon
        var group = $("[data-field='addon_id_top']");

        group.removeClass('btn-primary btn-default active').addClass('btn-default');
        if($('input#door_type').val() == 1){
            $(group[0]).addClass('disabled');
            $(group[1]).addClass('disabled');
            $(group[2]).addClass('disabled');
            $(group[3]).removeClass('btn-default').addClass('btn-primary active');
            $('input#addon_id_top').val($(group[3]).data('value'));
        } else {
            $(group[0]).addClass('disabled');
            $(group[1]).removeClass('btn-default').addClass('btn-primary active');
            $('input#addon_id_top').val($(group[1]).data('value'));
        }

    } else {
        //console.log('no vents');

        //remove the addon_id and addon_position_* values
        $('input#addon_id_left').val('');
        $('input#addon_id_right').val('');
        $('input#addon_position_top').val('');
        $('input#addon_position_left').val('');
        $('input#addon_position_right').val('');

        // do something to set all of the btn's to 'None'
        var group_top = $("[data-field='addon_id_top']");
        var group_left = $("[data-field='addon_id_left']");
        var group_right = $("[data-field='addon_id_right']");

        let addon_id_top = $('input#addon_id_top').val();

        if(addon_id_top == '' || addon_id_top == 0 || addon_id_top == null || addon_id_top == undefined) {
            $('input#addon_id_top').val('');
            group_top.removeClass('btn-primary btn-default active disabled').addClass('btn-default');
            $(group_top[0]).removeClass('btn-default').addClass('btn-primary active');
        }

        group_left.removeClass('btn-primary btn-default active').addClass('btn-default');
        group_right.removeClass('btn-primary btn-default active').addClass('btn-default');
        $(group_left[0]).removeClass('btn-default').addClass('btn-primary active');
        $(group_right[0]).removeClass('btn-default').addClass('btn-primary active');

    }

    // show correct number of vent options for selected number of panels
    $('div#vent-picker > div').addClass('hidden');
    $('div#vent-picker').attr('data-panels', panels);
    for(var i = 1; i <= panels; i++) {
        $('div#vent-picker > div#vent-'+ i).removeClass('hidden');
    }

    // if cill is set and above zero, add to draw params
    var cill = $('input#cill_id').val();

    // set the src of the door preview image
    var door_url = '/draw?door_type='+ door_type +'&panels='+panels+'&traffic='+traffic+'&hardware='+hardware+'&colour='+colour+'&blinds='+blinds+'&vent='+vent+'&vents='+vents+'&cill='+cill+'&glass='+glass;
    // $('img.image-url').attr('src', door_url);
    $('img.image-url').load(function() {
        $('i#loading-door').fadeOut('slow', function() {
            $(this).remove();
        });
    }).attr('src', door_url);
    // $('div#door-preview > img').attr('src', door_url);

    // update jetzoom image
    // door_url += '&zoom=1';
    // $('img.image-url').attr('data-jetzoom', 'zoomImage: \''+ door_url +'&zoom=1\'');
    // $('div#door-preview > img').attr('data-jetzoom', 'zoomImage: \''+ door_url +'\'');

    // refresh the image being used in the door zoom
    // door_zoom = $('img.image-url').data('JetZoom');
    // // door_zoom = $('div#door-preview > img').data('JetZoom');
    // door_zoom.destroy();
    // $('.jetzoom').JetZoom(zoom_options);

    // update the vent preview url
    $('img#vent-preview-image').attr('src', door_url + '&vent_preview=1');

    // hide the spinner is shown
    // if($('i#loading-door').is(':visible')) {
    //     $('i#loading-door').remove();
    // }
}


function get_price() {
    // get the type of door, upvc or aluminium
    var door_type = $designer.data('door-type');

    let cill_height = $('input#cill_id').val() != '' ? 30 : 0;
    let addon_height = $('input#addon_id_top').val() != '' ? 42 : 0;
    let actual_height = $('input#height').val() - cill_height - addon_height;

    // get width and height in metres
    var metres_w = $('input#width').val() / 1000;
    var metres_h = $('input#height').val() / 1000;

    // get the base price based on the type
    var price_per_panel = $designer.data('base1');

    // get number of panels selected
    var panels = $('input#panels').val();

    // calculate base price of panels
    var price = price_per_panel * panels;

    // calculate excess height
    if(max_height_intervals > 0 && max_height_interval_price > 0 && actual_height > std_height){
        let excess_height = actual_height - std_height;
        let intervals = Math.ceil(excess_height/max_height_intervals);
        let height_uplift = max_height_interval_price * intervals * panels;
        price += height_uplift;
    }

    // calculate discount on panels only - BEFORE VAT
    if($designer.data('discount') != undefined) {
        let discount = $designer.data('discount') * panels;
        price -= discount;
    }

    // calculate colour price
    $('*[data-parent-id="external-colours"]').each(function() {
        if($(this).hasClass('active') && $(this).data('ppp') != undefined) {
            price += ( panels * $(this).data('ppp'));
        }
    });

    // calculate glass price
    $('div#glazing-options > div').each(function() {
        if($(this).hasClass('active') && $(this).data('ppp') != undefined) {
            let glass_uplift = 0;
            let glass_price = panels * $(this).data('ppp')
            price += glass_price;

            let glass_height_intervals = $(this).data('interval');
            let glass_interval_price = $(this).data('interval-price');

            if(glass_height_intervals > 0 && glass_interval_price > 0 && actual_height > std_height){
                let g_excess_height = actual_height - std_height;
                let g_intervals = Math.ceil(g_excess_height/max_height_intervals);
                let glass_uplift = glass_interval_price * g_intervals * panels;
                price += glass_uplift;
            }

            if($designer.data('discount-glass') != undefined){
                let glass_discount = $designer.data('discount-blinds');
                let glass_discount_total = (glass_price + glass_uplift ) * glass_discount / 100;
                price -= glass_discount_total;
            }

        }
    });

    // calculate integral blinds price
    $('div#blind-options > div').each(function() {
        if($(this).hasClass('active') && $(this).data('ppp') != undefined) {
            let glass_uplift = 0;
            let glass_price = panels * $(this).data('ppp')
            price += glass_price;

            let glass_height_intervals = $(this).data('interval');
            let glass_interval_price = $(this).data('interval-price');

            if(glass_height_intervals > 0 && glass_interval_price > 0 && actual_height > std_height){
                let g_excess_height = actual_height - std_height;
                let g_intervals = Math.ceil(g_excess_height/max_height_intervals);
                let glass_uplift = glass_interval_price * g_intervals * panels;
                price += glass_uplift;
            }

            if($designer.data('discount-glass') != undefined){
                let glass_discount = $designer.data('discount-glass');
                let glass_discount_total = (glass_price + glass_uplift ) * glass_discount / 100;
                price -= glass_discount_total;
            }

        }
    });

    // calculate ventilation price
    if($('input#ventilation_id').val() != '') {
        var $vent = $('div#vent-options > div.active');
        var vent_ppp = $vent.data('base1');
        price += ( panels * vent_ppp );

        if($designer.data('discount-vents') != undefined){
            let vent_discount = $designer.data('discount-vents');
            let vent_discount_total = ( panels * vent_ppp ) * vent_discount / 100;
            price -= vent_discount_total;
        }
    }

    // calculate cill price
    if($('input#cill_id').val() != '') {
        var $cill = $('div#cill-options > div.active');
        var cill_ppm = $cill.data('base1');
        price += ( metres_w * cill_ppm );

        if($designer.data('discount-vents') != undefined){
            let cill_discount = $designer.data('discount-vents');
            let cill_discount_total = ( metres_w * cill_ppm ) * cill_discount / 100;
            price -= cill_discount_total;
        }
    }

    let addon_top = 0;
    let addon_right = 0;
    let addon_left = 0;

    // calculate addon price
    if($('input#addon_id_top').val() != '') {
        var $addon = $('#addon_top label.active');
        var addon_ppm = $addon.data('base1');
        addon_top = metres_w * addon_ppm;
        price += addon_top;
    }
    if($('input#addon_id_left').val() != '') {
        var $addon_left = $('#addon_left label.active');
        var addon_left_ppm = $addon_left.data('base1');
        addon_left = metres_w * addon_left_ppm;
        price += addon_left;
    }
    if($('input#addon_id_right').val() != '') {
        var $addon_right = $('#addon_right label.active');
        var addon_right_ppm = $addon_right.data('base1');
        addon_right = metres_w * addon_right_ppm;
        price += addon_right;
    }

    if($designer.data('discount-addons') != undefined){
        let addons_discount = $designer.data('discount-addons');
        let addons_discount_total = (addon_top + addon_left + addon_right ) * addons_discount / 100;
        price -= addons_discount_total;
    }

    // push price to dom
    $('span#price').html(price.toFixed(2));
    $('input#price_net').val(price.toFixed(2));
}


function intialise_slider(slider_id) {
    switch(slider_id) {
        case 3:
            // external colour scroller
            if(!$('#external-colours').hasClass('slick-initialized')) {
                $('#external-colours').slick({
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    prevArrow: $('.external-colour-prev'),
                    nextArrow: $('.external-colour-next'),
                    infinite: false,
                    respondTo: 'slider',
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 5
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 5
                            }
                        },
                        {
                            breakpoint: 360,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        }
                    ]
                });
            }
            if(!$('#internal-colours').hasClass('slick-initialized') && $('#internal-colours').length) {
                $('#internal-colours').slick({
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    prevArrow: $('.internal-colour-prev'),
                    nextArrow: $('.internal-colour-next'),
                    infinite: false,
                    respondTo: 'slider',
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 5
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 5
                            }
                        },
                        {
                            breakpoint: 360,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        }
                    ]
                });
            }
            break;
    }
}